import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
} from "@mui/material";
import "./LatestProducts.css"; // Assuming you have a CSS file for custom styles
import { useGetProductsQuery } from "../redux/api/productsApi";
import StarRatings from "react-star-ratings";
import { useSearchParams } from "react-router-dom";
import Loader from "./Loader"; // Import the loader
import toast from "react-hot-toast";
import CustomPagination from "./CustomPagination";

const LatestProducts = () => {
  let [searchParams] = useSearchParams();
  const page = searchParams.get("page") || 1;
  const keyword = searchParams.get("keyword") || "";
  const params = { page, keyword };
  const { data, isLoading, error, isError } = useGetProductsQuery(params);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.message);
    }
  }, [isError]);

  if (isLoading) return <Loader />;
  return (
    <Container className="App">
      <Typography
        variant="h3"
        color="var(--gold)"
        gutterBottom
        id="products_heading"
      >
        {keyword
          ? `${data?.products?.length} Product(s) Found`
          : "Latest Products"}
      </Typography>
      <Container className="products-latest">
        {data?.products?.map((product) => (
          <Box>
            <Grid container spacing={3}>
              {/* Product Item 1 */}
              <Grid item xs={12} sm={6} md={3}>
                <Card className="card" raised>
                  <CardMedia
                    component="img"
                    height="200"
                    image={product?.images[0]?.url}
                    alt={product?.name}
                    className="card-img-top"
                  />

                  <CardContent className="card-body">
                    <Typography variant="h5" component="div" gutterBottom>
                      <Link
                        to={`/product/${product?._id}`}
                        className="product-link"
                      >
                        {product?.name}
                      </Link>
                    </Typography>
                    <Box className="ratings" display="flex" alignItems="center">
                      <StarRatings
                        rating={product?.ratings}
                        starRatedColor="#ffb829"
                        starDimension="24px"
                        starSpacing="1px"
                        numberOfStars={5}
                        name="rating"
                      />
                      <Typography id="no_of_reviews" className="ps-2" pt={1}>
                        ({product?.numOfReviews})
                      </Typography>
                    </Box>
                    <Typography
                      variant="h6"
                      color="textSecondary"
                      className="products-price"
                    >
                      ₦{product?.price}
                    </Typography>
                    <Link to={`/product/${product?._id}`} id="view_btn">
                      View Details
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
              {/* End Product Item 1 */}
            </Grid>
          </Box>
        ))}
      </Container>
      <CustomPagination
        resPerPage={data?.resPerPage}
        filteredProductsCount={data?.filteredProductsCount}
      />
    </Container>
  );
};

export default LatestProducts;
