import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import "./Loader.css";
import LoadingGif from "../images/loading.gif"; // Use your GIF here

const Loader = () => {
  return (
    <div className="loader-container">
      {/* This is the GIF loader */}
      <div className="gif-wrapper">
        <img src={LoadingGif} alt="Loading GIF" className="loading-gif" />
      </div>

      {/* Small circular progress at the bottom center */}
      <div className="bottom-loader">
        <CircularProgress size={50} thickness={4} />
      </div>

      {/* Optional loading text */}
      <div className="loading-text">Loading...</div>
    </div>
  );
};

export default Loader;
