import React from "react";
import { Box, Typography, IconButton, Grid } from "@mui/material";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import Logo from "../images/logo.png";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "black",
        color: "white",
        py: 5,
        textAlign: "center",
      }}
    >
      <Grid container spacing={2} justifyContent="center">
        {/* Logo Section */}
        <Grid item xs={12} md={4}>
          <img
            src={Logo}
            alt="Company Logo"
            style={{ height: "80px", marginBottom: "8px" }}
          />
          <Typography variant="h6" component="div" gutterBottom>
            Chrysos Noir
          </Typography>
        </Grid>

        {/* Social Media Section */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" component="div" gutterBottom>
            Follow Us
          </Typography>
          <div>
            <IconButton
              href="https://facebook.com"
              target="_blank"
              rel="noopener"
              aria-label="Facebook"
              sx={{
                color: "white",
                "&:hover": {
                  transform: "scale(1.2)",
                  color: "#4267B2", // Facebook color
                },
                transition:
                  "transform 0.3s ease-in-out, color 0.3s ease-in-out",
              }}
            >
              <FaFacebookF />
            </IconButton>

            <IconButton
              href="https://instagram.com"
              target="_blank"
              rel="noopener"
              aria-label="Instagram"
              sx={{
                color: "white",
                "&:hover": {
                  transform: "scale(1.2)",
                  color: "#E1306C", // Instagram color
                },
                transition:
                  "transform 0.3s ease-in-out, color 0.3s ease-in-out",
              }}
            >
              <FaInstagram />
            </IconButton>

            <IconButton
              href="https://twitter.com"
              target="_blank"
              rel="noopener"
              aria-label="Twitter"
              sx={{
                color: "white",
                "&:hover": {
                  transform: "scale(1.2)",
                  color: "#1DA1F2", // Twitter color
                },
                transition:
                  "transform 0.3s ease-in-out, color 0.3s ease-in-out",
              }}
            >
              <FaTwitter />
            </IconButton>

            <IconButton
              href="https://linkedin.com"
              target="_blank"
              rel="noopener"
              aria-label="LinkedIn"
              sx={{
                color: "white",
                "&:hover": {
                  transform: "scale(1.2)",
                  color: "#0077B5", // LinkedIn color
                },
                transition:
                  "transform 0.3s ease-in-out, color 0.3s ease-in-out",
              }}
            >
              <FaLinkedinIn />
            </IconButton>
          </div>
        </Grid>

        {/* Contact Section */}
        <Grid item xs={12} md={4}>
          <Typography variant="h6" component="div" gutterBottom>
            Contact Us
          </Typography>
          <Typography variant="body1" component="p">
            Phone: (123) 456-7890
          </Typography>
          <Typography variant="body1" component="p">
            Email: contact@chrysosnoir.com
          </Typography>
        </Grid>
      </Grid>

      {/* Footer Bottom */}
      <Box mt={4}>
        <Typography variant="body2">
          &copy; {new Date().getFullYear()} Chrysos Noir. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
