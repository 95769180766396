import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router, // Importing BrowserRouter as Router
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/Home";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Loader from "./components/Loader"; // Import the loader
import { Toaster } from "react-hot-toast";
import ProductDetails from "./pages/ProductDetails";

// Define MUI theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#d4af37",
    },
    secondary: {
      main: "#F6F6F6",
    },
  },
  typography: {
    fontFamily: '"Euclid Circular A"',
  },
});

// This component handles route changes and loading state
const MainContent = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation(); // Detect current route

  useEffect(() => {
    setLoading(true);

    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000); // Simulate loading time

    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      {loading && <Loader />} {/* Conditionally render the Loader */}
      {!loading && (
        <>
          <Navbar />
          <Toaster position="top-center" />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/product/:id" element={<ProductDetails />} />
          </Routes>
          <Footer />
        </>
      )}
    </>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <MainContent /> {/* MainContent is now inside the Router context */}
      </Router>
    </ThemeProvider>
  );
};

export default App;
