import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Avatar,
  Menu,
  MenuItem,
  Button,
  Badge,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { FaShoppingCart } from "react-icons/fa";
import { Menu as MenuIcon, Close as CloseIcon } from "@mui/icons-material";
import Logo from "../images/logo.png";
import Search from "./Search";
import "./Navbar.css";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar
        position="sticky"
        className="appbar"
        color="default"
        style={{ borderRadius: "71px", zIndex: "99" }}
      >
        <Toolbar>
          {/* Logo */}
          <div style={{ flexGrow: 1 }}>
            <Link to="/">
              <img src={Logo} alt="Logo" style={{ height: "80px" }} />
            </Link>
          </div>
          <div className="web-title">
            <Link to="/" stlye={{ textDecoration: "none" }}>
              <h1>Chrysos Noir</h1>
            </Link>
          </div>

          {/* Search Bar (Hidden on Mobile) */}
          <div
            className="desktop-search"
            style={{ backgroundColor: "white", borderRadius: "60px" }}
          >
            <Search />
          </div>

          {/* Cart and User Section */}
          <div
            className="desktop-menu"
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* Cart */}
            <IconButton
              href="/cart"
              className="blue"
              color="inherit"
              sx={{ display: { xs: "none", md: "flex" } }}
            >
              <Badge badgeContent={0} color="primary">
                <FaShoppingCart />
              </Badge>
              <Typography
                variant="body1"
                className="blue"
                style={{ marginLeft: "8px" }}
              >
                Cart
              </Typography>
            </IconButton>

            {/* User Dropdown */}
            <IconButton
              sx={{ display: { xs: "none", md: "flex" } }}
              onClick={handleMenuClick}
              className="blue"
              color="inherit"
            >
              <Avatar alt="User Avatar" src="../images/default_avatar.jpg" />
              <Typography
                variant="body1"
                className="blue"
                style={{ marginLeft: "8px" }}
              >
                User
              </Typography>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={handleClose}
                component="a"
                href="/admin/dashboard"
              >
                Dashboard
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="/me/orders">
                Orders
              </MenuItem>
              <MenuItem onClick={handleClose} component="a" href="/me/profile">
                Profile
              </MenuItem>
              <MenuItem
                onClick={handleClose}
                component="a"
                href="/"
                style={{ color: "red" }}
              >
                Logout
              </MenuItem>
            </Menu>

            {/* Login Button */}
            <Button
              sx={{ display: { xs: "none", md: "flex" } }}
              href="/login"
              color="inherit"
              style={{ marginLeft: "16px" }}
              className="blue"
            >
              Login
            </Button>
          </div>

          {/* Hamburger Menu for Mobile */}
          <div className="mobile-menu">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="right"
              open={drawerOpen}
              onClose={toggleDrawer(false)}
            >
              <Box
                sx={{ width: 250 }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <IconButton onClick={toggleDrawer(false)}>
                  <CloseIcon />
                </IconButton>
                <List>
                  <ListItem button component="a" href="/">
                    <ListItemText primary="Home" />
                  </ListItem>
                  <ListItem button component="a" href="/admin/dashboard">
                    <ListItemText primary="Dashboard" />
                  </ListItem>
                  <ListItem button component="a" href="/me/orders">
                    <ListItemText primary="Orders" />
                  </ListItem>
                  <ListItem button component="a" href="/me/profile">
                    <ListItemText primary="Profile" />
                  </ListItem>
                  <ListItem button component="a" href="/login">
                    <ListItemText primary="Login" />
                  </ListItem>
                  <ListItem button component="a" href="/cart">
                    <ListItemText primary="Cart" />
                  </ListItem>
                </List>
              </Box>
            </Drawer>
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Navbar;
